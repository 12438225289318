import '../App.css';
import Row from './row';
import Transfer from './transfer';
import Calculate from './calculate';
import { useState } from 'react';
import { useEffect } from 'react';
import { GPAProvider } from './gpacontext';
import { TextField } from '@mui/material';


function MainPanel() {
    const [year, setYear] = useState();

    useEffect(() => {
      setYear(2014);
    }, []);

    const handleYearChange = (event) => {
        setYear(Number(event.target.value));
    };

  return (
    <GPAProvider>
      <div className="mainPanel" >
          <TextField size="small" label="Starting Year" id="yearPicker" min="2015" max="2040"  onChange={handleYearChange}/>

          <div id="year0">
            <Transfer />
          </div>

          <div className="years" id="year1">
            <Row season="Fall" year={year} sem= "0"/>
            <Row season="Spring" year={year+1} sem= "1"/>
            <Row season="Summer" year={year+1} sem= "2"/>
          </div>

          <div className="years" id="year2">
          <Row season="Fall" year={year+1} sem= "3"/>
          <Row season="Spring" year={year+2} sem= "4"/>
          <Row season="Summer" year={year+2} sem= "5"/>
          </div>

          <div className="years" id="year3">
          <Row season="Fall" year={year+2} sem= "6" />
          <Row season="Spring" year={year+3} sem= "7"/>
          <Row season="Summer" year={year+3} sem= "8"/>
          </div>

          <div className="years" id="year4">
          <Row season="Fall" year={year+3} sem= "9"/>
          <Row season="Spring" year={year+4} sem= "10"/>
          <Row season="Summer" year={year+4} sem= "11"/>
          </div>
          
      </div>
    </GPAProvider>
  );
}




export default MainPanel;